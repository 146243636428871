import Timelines from "../components/Timelines";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import { motion } from "framer-motion";

const About = () => {
  const {intro, elem} = useContext(AppContext);

    return (
        <motion.div
        className="about-main"
        variants={intro}
        initial="hidden"
        animate="visible">
          <motion.h1 variants={elem}>I'm <span>Marco.</span></motion.h1>
          <div className="about-me-wrapper">
            <motion.div className="me" variants={elem}>
              <div className="me-addon">
                <h4>Listening on Youtube Music</h4>
                <a href="https://www.youtube.com/watch?v=pQsF3pzOc54&ab_channel=MacDeMarco-Topic" target="blank_">
                  <p><span className="dot">•</span>Mac DeMarco - Chamber of Reflection</p>
                </a>
              </div>
            </motion.div>
            <motion.div className="about" variants={elem}>
              <h1>I bring a unique blend of skills.</h1>
              <p>Over that last several years I served as a human resource professional in one of the most elite military forces in the world. I transitoned out to pursue a career in software engineering, a field I'm truly passionate about.</p>
              <p>With any new journey, you need to know your why. For me it's because it makes me happy and I have fun doing it. The challenges of problem solving is just a plus for me.</p>
            </motion.div>
          </div>
         <Timelines />
          <motion.div className="about-2">
            <motion.h1 variants={elem}>Self-motivation.</motion.h1>
            <motion.p variants={elem}>Why is it important? It's helped me get through the most difficult times in my life. It enables performance, personal growth, and learning. As a passionate software engineer, I see programming as a craftsmanship. You need to be self-motivated to master your craft. I have the desire to consistantly learn more. So if you feel the same, let's connect.</motion.p>
          </motion.div>
        </motion.div>
    )
}

export default About;