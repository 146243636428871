import React from 'react';
import ReactDom from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App.js';
import './App.css';
import { AppContextProvider } from './context/AppContext.js';

const root = ReactDom.createRoot(document.getElementById('root'));
root.render(
<BrowserRouter>
      <AppContextProvider>
            <App />
      </AppContextProvider>
</BrowserRouter>
);