import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home.js";
import About from "./pages/About.js";
import Navbar from "./components/Navbar.js";
import Gradient from "./components/Gradient.js";
import Contact from "./pages/Contact.js";
import Footer from "./components/Footer.js";
import ScrollToTop from "./components/ScrollToTop.js";

const App = () => {

    return (
        <div className='app'>
            <Navbar/>
            <Gradient />
            <Routes>
                <Route path='/' element={<Home />}/>
                <Route path='/About' element={<About />}/>
                <Route path='/Contact' element={<Contact />}/>
            </Routes>
            <Footer />
            <ScrollToTop />
        </div>
    )
};

export default App;