import emailjs from '@emailjs/browser';
import { useRef, useContext } from "react";
import AppContext from '../context/AppContext';
import { motion } from 'framer-motion';

const Contact = () => {
    const form = useRef();
    const {intro, elem} = useContext(AppContext);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_9qaou9f', 'template_8ppwe9t', form.current, 'Zo2rcT9HaHM1vaCKE')
          .then((result) => {
              if(result.text === 'OK'){
                document.getElementById("form").reset();
              }
          }, (error) => {
              console.log(error.text);
          });
    }

    return (
        <motion.div
        className="contact-main"
        variants={intro}
        initial="hidden"
        animate="visible">
            <motion.h1 variants={elem}>Message.</motion.h1>
                <form id="form" className="form-wrapper" ref={form} onSubmit={sendEmail} autoComplete="off">
                    <div className="fm-rw-1">
                        <input required type="text" placeholder="Fullname" name="user_name" autoComplete="off"/>
                        <input required type="email" placeholder="Email" name="user_email" autoComplete="off"/>
                    </div>
                    <div className="fm-rw-2">
                        <input type="tel" placeholder="Phone" name="user_phone" autoComplete="off"/>
                        <input type="text" placeholder="Organization" name="user_org" autoComplete="off"/>
                    </div>
                        <input className="input-subj" required type="text" placeholder="Subject" name="from_subject" autoComplete="off"/>
                        <textarea name="message" placeholder="Say Hi" autoComplete="off"/>
                    <div className='btn-wrapper'>
                        <button className="msg-btn" type="submit">Send</button>
                    </div>
                </form>
        </motion.div>
    )
}

export default Contact;