import { Outlet, useLocation, Link } from "react-router-dom";
import AppContext from "../context/AppContext";
import { useContext } from "react";

const Navbar = () => {
    const location = useLocation();
    let loc = location.pathname.replace("/", "");
    const {navbar, setNavbar} = useContext(AppContext);
    const links = [
        {
            name: "Work",
            path: "/"
        },
        { 
            name: "About",
            path: "/About"
        },
        {
            name: "Contact",
            path: "/Contact"
        }
    ];

    const showNavbar = () => {
        if(window.scrollY >= 30){
            setNavbar(true)
        }else{
            setNavbar(false)
        }
    }
    window.addEventListener('scroll', showNavbar);

    return (
        <nav>
            <ul className={navbar? "navbar-trans" : "navbar"}>
                {
                    links.map((link, i) => (
                        <Link
                        className="nav-links"
                        to={link.path}
                        key={i}><li>{link.name}</li></Link>
                    ))
                }
            <div
            id="animation"
            className={`animation-${loc}`}></div> 
            </ul>
            <Outlet/>
        </nav>
    )
}

export default Navbar;

