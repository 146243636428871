import AppContext from "../context/AppContext";
import { useContext } from "react";
import { motion } from "framer-motion";

const Timelines = () => {
    const {elem} = useContext(AppContext);

    return (
        <div className="tl">
            <motion.h1 variants={elem}>Experience.</motion.h1>
            <div className="tl-main">
                <motion.div id="xp" className="xp-6" variants={elem}>
                    <div>
                        <h4>Galvanize</h4>
                        <p>Software Engineer</p>
                    </div>
                    <p>22</p>
                </motion.div>
                <motion.div id="xp" className="xp-5" variants={elem}>
                    <div>
                        <h4>160th SOAR</h4>
                        <p>Sr HR Assisant</p>
                    </div>
                    <p>19 - 22</p>
                </motion.div>
                <motion.div id="xp" className="xp-4" variants={elem}>
                    <div>
                        <h4>I Corps</h4>
                        <p>HR Plans and Operations</p>
                    </div>
                    <p>18 - 19</p>
                </motion.div>
                <motion.div id="xp" className="xp-3" variants={elem}>
                    <div>
                        <h4>4th ID</h4>
                        <p>HR Personnel Services</p>
                    </div>
                    <p>16 - 18</p>
                </motion.div>
                <motion.div id="xp" className="xp-2" variants={elem}>
                    <div>
                        <h4>2nd ID</h4>
                        <p>HR Specialist</p>
                    </div>
                    <p>15 - 16</p>
                </motion.div>
                <motion.div id="xp" className="xp-1" variants={elem}>
                    <div>
                        <h4>4th ID</h4>
                        <p>HR Specialist</p>
                    </div>
                    <p>12 - 15</p>
                </motion.div>
        </div>
      </div>

    )
}

export default Timelines;