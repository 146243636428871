import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <div className="foot-main">
            <div className="my-sign">
                <p>2023 Marco Diaz</p>
            </div>
            <div className="f-c">
                <h3>Elsewhere.</h3>
                <a href="https://github.com/MarcoDayz" target="blank_"><p>Github</p></a>
                <a href="https://www.hackerrank.com/diaz_marco8686?hr_r=1" target="blank_"><p>Hackerrank</p></a>
                {/* <a href="https://leetcode.com/MarcoDayz/" target="blank_"><p>Leetcode</p></a> */}
                <a href="https://www.linkedin.com/in/mark-days/" target="blank_"><p>Linkedin</p></a>
            </div>
            <div className="f-c">
                <h3>Contact.</h3>
                <Link to={"/Contact"}><p>Message</p></Link>
                {/* <a href="mailto: diaz.marco8686@gmail.com">Email</a> */}
            </div>
        </div>
    )
}

export default Footer;